<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      v-if="resolutionScreen >= 500"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title
          >{{ $t("dragdrop.header") }}
          <v-spacer></v-spacer>
          <v-icon :color="color.theme" dark size="35">mdi-folder-upload</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-tabs v-model="tab">
            <v-tab v-if="datauploading.length > 0">{{ $t("uploadfile.uploadingfile")}}</v-tab>
            <v-tab v-if="datauploadduplicate.length > 0">{{ $t("uploadfile.duplicate")}}</v-tab>
            <v-tab v-if="datauploadsuccess.length > 0">{{ $t("uploadfile.success")}}</v-tab>
            <v-tab v-if="datauploadfail.length > 0">{{ $t("uploadfile.fail")}}</v-tab>
            <v-tabs-items style="height: 600px; overflow-y:auto;" v-model="tab">
              <!-- กำลังอัปโหลด -->
              <v-tab-item v-if="datauploading.length > 0">
                <v-list dense tile>
                  <v-list-item
                    v-for="(item, i) in datauploading"
                    :key="i"
                    dense
                    three-line
                  >
                    <v-list-item-avatar
                      ><v-icon large :color="item.icon[1]">{{
                        item.icon[0]
                      }}</v-icon></v-list-item-avatar
                    >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle
                        style="color: green"
                        v-if="item.status.code === 'success' && item.copy_paste === false"
                        dense
                        hide-details
                      >
                        {{ $t("dragdrop.uploadsuccess") }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: green"
                        v-else-if="item.status.code === 'success' && item.copy_paste === true"
                        dense
                        hide-details
                      >
                        {{ $t('copyandpaste.copied_success') }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: #fb8c00"
                        v-else-if="item.status.code === 'duplicate' || item.color === 'warning'"
                        dense
                        hide-details
                      >
                        {{ $t(item.status.message) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: red"
                        v-else-if="item.status.code === 'fail' || item.status.code === 'cancel' || item.status.code === 'skip'"
                        dense
                        hide-details
                      >
                        {{ $t(item.status.message) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else dense hide-details>
                        {{ $t("dragdrop.uploading") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="text-center">
                      <v-btn
                        fab
                        small
                        icon
                        v-if="item.status.code !== 'start' && item.status.code !== 'Uploading'"
                        style="cursor: context-menu"
                      >
                        <v-progress-circular
                          :rotate="90"
                          :size="30"
                          :width="3"
                          :value="100"
                          :color="item.color"
                        >
                          <v-icon v-if="item.status.code === 'success'">mdi-check</v-icon>
                          <v-icon v-else-if="item.status.code === 'cancel' || item.status.code === 'fail'">mdi-close</v-icon>
                          <v-icon v-else>mdi-exclamation</v-icon>
                        </v-progress-circular>
                      </v-btn>
                      <v-hover v-else v-slot="{ hover }">
                        <v-btn
                          fab
                          small
                          icon
                          @click="$emit('loader'), $emit('canceloneitem', item)"
                        >
                          <v-progress-circular  
                            :rotate="90"
                            :size="30"
                            :width="3"
                            :value="!item.value
                              ? 0
                              : parseInt(item.value)"
                            :color="hover ? 'error' : color.theme"
                          >
                            <span v-if="!hover">
                              {{
                                !item.value
                                  ? 0
                                  : parseInt(item.value)
                              }}
                            </span>
                            <v-icon
                              v-else
                              color="error"
                            >
                              mdi-close
                            </v-icon>
                          </v-progress-circular>
                        </v-btn>
                      </v-hover>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-tab-item>
              <!-- ไฟล์ซ้ำ -->
              <v-tab-item v-if="datauploadduplicate.length > 0">
                <v-list dense tile>
                  <v-list-item
                    v-for="(item, i) in datauploadduplicate"
                    :key="i"
                    dense
                    three-line
                  >
                    <v-list-item-avatar
                      ><v-icon large :color="item.icon[1]">{{
                        item.icon[0]
                      }}</v-icon></v-list-item-avatar
                    >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle
                        style="color: green"
                        v-if="item.status.code === 'success' && item.copy_paste === false"
                        dense
                        hide-details
                      >
                        {{ $t("dragdrop.uploadsuccess") }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: green"
                        v-else-if="item.status.code === 'success' && item.copy_paste === true"
                        dense
                        hide-details
                      >
                        {{ $t('copyandpaste.copied_success') }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: #fb8c00"
                        v-else-if="item.status.code === 'duplicate' || item.color === 'warning'"
                        dense
                        hide-details
                      >
                        {{ $t(item.status.message) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: red"
                        v-else-if="item.status.code === 'fail' || item.status.code === 'cancel' || item.status.code === 'skip'"
                        dense
                        hide-details
                      >
                        {{ $t(item.status.message) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else dense hide-details>
                        {{ $t("dragdrop.uploading") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="text-center">
                      <v-btn
                        fab
                        small
                        icon
                        v-if="item.status.code !== 'start' && item.status.code !== 'Uploading'"
                        style="cursor: context-menu"
                      >
                        <v-progress-circular
                          :rotate="90"
                          :size="30"
                          :width="3"
                          :value="100"
                          :color="item.color"
                        >
                          <v-icon v-if="item.status.code === 'success'">mdi-check</v-icon>
                          <v-icon v-else-if="item.status.code === 'cancel' || item.status.code === 'fail'">mdi-close</v-icon>
                          <v-icon v-else>mdi-exclamation</v-icon>
                        </v-progress-circular>
                      </v-btn>
                      <v-hover v-else v-slot="{ hover }">
                        <v-btn
                          fab
                          small
                          icon
                          @click="$emit('loader'), $emit('canceloneitem', item)"
                        >
                          <v-progress-circular  
                            :rotate="90"
                            :size="30"
                            :width="3"
                            :value="!item.value
                              ? 0
                              : parseInt(item.value)"
                            :color="hover ? 'error' : color.theme"
                          >
                            <span v-if="!hover">
                              {{
                                !item.value
                                  ? 0
                                  : parseInt(item.value)
                              }}
                            </span>
                            <v-icon
                              v-else
                              color="error"
                            >
                              mdi-close
                            </v-icon>
                          </v-progress-circular>
                        </v-btn>
                      </v-hover>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-tab-item>
              <!-- อัปสำเร็จ -->
              <v-tab-item v-if="datauploadsuccess.length > 0">
                <v-list dense tile>
                  <v-list-item
                    v-for="(item, i) in datauploadsuccess"
                    :key="i"
                    dense
                    three-line
                  >
                    <v-list-item-avatar
                      ><v-icon large :color="item.icon[1]">{{
                        item.icon[0]
                      }}</v-icon></v-list-item-avatar
                    >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle
                        style="color: green"
                        v-if="item.status.code === 'success' && item.copy_paste === false"
                        dense
                        hide-details
                      >
                        {{ $t("dragdrop.uploadsuccess") }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: green"
                        v-else-if="item.status.code === 'success' && item.copy_paste === true"
                        dense
                        hide-details
                      >
                        {{ $t('copyandpaste.copied_success') }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: #fb8c00"
                        v-else-if="item.status.code === 'duplicate' || item.color === 'warning'"
                        dense
                        hide-details
                      >
                        {{ $t(item.status.message) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: red"
                        v-else-if="item.status.code === 'fail' || item.status.code === 'cancel' || item.status.code === 'skip'"
                        dense
                        hide-details
                      >
                        {{ $t(item.status.message) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else dense hide-details>
                        {{ $t("dragdrop.uploading") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="text-center">
                      <v-btn
                        fab
                        small
                        icon
                        v-if="item.status.code !== 'start' && item.status.code !== 'Uploading'"
                        style="cursor: context-menu"
                      >
                        <v-progress-circular
                          :rotate="90"
                          :size="30"
                          :width="3"
                          :value="100"
                          :color="item.color"
                        >
                          <v-icon v-if="item.status.code === 'success'">mdi-check</v-icon>
                          <v-icon v-else-if="item.status.code === 'cancel' || item.status.code === 'fail'">mdi-close</v-icon>
                          <v-icon v-else>mdi-exclamation</v-icon>
                        </v-progress-circular>
                      </v-btn>
                      <v-hover v-else v-slot="{ hover }">
                        <v-btn
                          fab
                          small
                          icon
                          @click="$emit('loader'), $emit('canceloneitem', item)"
                        >
                          <v-progress-circular  
                            :rotate="90"
                            :size="30"
                            :width="3"
                            :value="!item.value
                              ? 0
                              : parseInt(item.value)"
                            :color="hover ? 'error' : color.theme"
                          >
                            <span v-if="!hover">
                              {{
                                !item.value
                                  ? 0
                                  : parseInt(item.value)
                              }}
                            </span>
                            <v-icon
                              v-else
                              color="error"
                            >
                              mdi-close
                            </v-icon>
                          </v-progress-circular>
                        </v-btn>
                      </v-hover>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-tab-item>
              <!-- อัปไม่สำเร็จ -->
              <v-tab-item v-if="datauploadfail.length > 0">
                <v-list dense tile>
                  <v-list-item
                    v-for="(item, i) in datauploadfail"
                    :key="i"
                    dense
                    three-line
                  >
                    <v-list-item-avatar
                      ><v-icon large :color="item.icon[1]">{{
                        item.icon[0]
                      }}</v-icon></v-list-item-avatar
                    >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle
                        style="color: green"
                        v-if="item.status.code === 'success' && item.copy_paste === false"
                        dense
                        hide-details
                      >
                        {{ $t("dragdrop.uploadsuccess") }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: green"
                        v-else-if="item.status.code === 'success' && item.copy_paste === true"
                        dense
                        hide-details
                      >
                        {{ $t('copyandpaste.copied_success') }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: #fb8c00"
                        v-else-if="item.status.code === 'duplicate' || item.color === 'warning'"
                        dense
                        hide-details
                      >
                        {{ $t(item.status.message) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="color: red"
                        v-else-if="item.status.code === 'fail' || item.status.code === 'cancel' || item.status.code === 'skip'"
                        dense
                        hide-details
                      >
                        {{ $t(item.status.message) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else dense hide-details>
                        {{ $t("dragdrop.uploading") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="text-center">
                      <v-btn
                        fab
                        small
                        icon
                        v-if="item.status.code !== 'start' && item.status.code !== 'Uploading'"
                        style="cursor: context-menu"
                      >
                        <v-progress-circular
                          :rotate="90"
                          :size="30"
                          :width="3"
                          :value="100"
                          :color="item.color"
                        >
                          <v-icon v-if="item.status.code === 'success'">mdi-check</v-icon>
                          <v-icon v-else-if="item.status.code === 'cancel' || item.status.code === 'fail'">mdi-close</v-icon>
                          <v-icon v-else>mdi-exclamation</v-icon>
                        </v-progress-circular>
                      </v-btn>
                      <v-hover v-else v-slot="{ hover }">
                        <v-btn
                          fab
                          small
                          icon
                          @click="$emit('loader'), $emit('canceloneitem', item)"
                        >
                          <v-progress-circular  
                            :rotate="90"
                            :size="30"
                            :width="3"
                            :value="!item.value
                              ? 0
                              : parseInt(item.value)"
                            :color="hover ? 'error' : color.theme"
                          >
                            <span v-if="!hover">
                              {{
                                !item.value
                                  ? 0
                                  : parseInt(item.value)
                              }}
                            </span>
                            <v-icon
                              v-else
                              color="error"
                            >
                              mdi-close
                            </v-icon>
                          </v-progress-circular>
                        </v-btn>
                      </v-hover>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="dataupload.filter(item => item.status.code === 'Uploading' || item.status.code === 'start' || item.status.code === 'duplicate').length === 0"
            color="red"
            outlined
            @click="$emit('closedetailupload')"
            >{{ $t("changeName.close") }}</v-btn
          >
          <v-btn
            color="red"
            class="white--text"
            @click="$emit('cancel')"
            :disabled="length_processing === dataupload.length"
            >{{ $t("dragdrop.cancel") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="hide()"
            >{{ $t("dragdrop.hide") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="shower"
      v-else
      persistent
      scrollable
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
    >
      <v-card>
        <v-card-text class="pa-5 text-center" style="overflow-y:hidden;">
          <v-layout class="pt-2">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPageDialog">{{ $t("dragdrop.header") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon :color="color.theme" @click="hide()"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text style="max-height:600px;">
          <v-list dense tile>
            <v-list-item
              v-for="(item, i) in dataupload"
              :key="i"
              dense
              three-line
              class="mx-n4"
            >
              <v-list-item-avatar
                ><v-icon large :color="item.icon[1]">{{
                  item.icon[0]
                }}</v-icon></v-list-item-avatar
              >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle
                  style="color: green"
                  v-if="item.status.code === 'success' && item.color !== 'red'"
                  dense
                  hide-details
                >
                  {{ $t("dragdrop.uploadsuccess") }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  style="color: #fb8c00"
                  v-else-if="item.status.code === 'duplicate' || item.color === 'warning'"
                  dense
                  hide-details
                >
                  {{ $t(item.status.message) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  style="color: red"
                  v-else-if="item.status.code === 'fail' || item.status.code === 'cancel' || item.status.code === 'skip'"
                  dense
                  hide-details
                >
                  {{ $t(item.status.message) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else dense hide-details>
                  {{ $t("dragdrop.uploading") }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="text-center">
                <v-btn
                  fab
                  small
                  icon
                  v-if="item.status.code !== 'start' && item.status.code !== 'Uploading'"
                  style="cursor: context-menu"
                >
                  <v-progress-circular
                    :rotate="90"
                    :size="30"
                    :width="3"
                    :value="100"
                    :color="item.color"
                  >
                    <v-icon v-if="item.status.code === 'success'">mdi-check</v-icon>
                    <v-icon v-else-if="item.status.code === 'cancel' || item.status.code === 'fail'">mdi-close</v-icon>
                    <v-icon v-else>mdi-exclamation</v-icon>
                  </v-progress-circular>
                </v-btn>
                <v-hover v-else v-slot="{ hover }">
                  <v-btn
                    fab
                    small
                    icon
                    @click="$emit('loader'), $emit('canceloneitem', item)"
                  >
                    <v-progress-circular  
                      :rotate="90"
                      :size="30"
                      :width="3"
                      :value="!item.value
                        ? 0
                        : parseInt(item.value)"
                      :color="hover ? 'error' : color.theme"
                    >
                      <span v-if="!hover">
                        {{
                          !item.value
                            ? 0
                            : parseInt(item.value)
                        }}
                      </span>
                      <v-icon
                        v-else
                        color="error"
                      >
                        mdi-close
                      </v-icon>
                    </v-progress-circular>
                  </v-btn>
                </v-hover>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <div class="text-center my-4">
          <v-btn
            :style="btnAction"
            class="mr-2"
            height="40px"
            width="70%"
            color="red"
            outlined
            @click="hide()"
            >{{ $t("changeName.close") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

// import dl_movefile from "../optional/dialog-movefile";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show", "dataupload", "listupload", "length_processing"],
  data: function() {
    return {
      listdataupload: [],
      fileUpload: [],
      showMenu: false,
      showitem: false,
      x: 0,
      y: 0,
      tab: null,
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closedetailupload");
        }
      },
    },
    datauploadfail() {
      return (
        this.dataupload.filter((item) => {
          return (
            item.status.code === "fail" ||
            item.status.code === "skip" ||
            item.status.code === "cancel"
          );
        })
      );
    },
    datauploadsuccess() {
      return (
        this.dataupload.filter((item) => {
          return (
            item.status.code === "success"
          );
        })
      );
    },
    datauploadduplicate() {
      return (
        this.dataupload.filter((item) => {
          return (
            item.status.code === "duplicate"
          );
        })
      );
    },
    datauploading() {
      return (
        this.dataupload.filter((item) => {
          return (
            item.status.code === "start" ||
            item.status.code === "Uploading"
          );
        })
      );
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษร header ของ mobile
    headerPageDialog() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        this.listdataupload = [];
        this.listdataupload = this.dataupload;

        console.log(this.show);
        console.log(this.dataupload);
      } else {
      }
    },
  },
  methods: {
    hide() {
      this.$emit("hidedetailupload");
      this.listdataupload = [];
    },
    cancel(item) {
      this.$emit("cancelupload", item);
    },
  },
  created() {},
};
</script>
<style>
#app_currentrightclick {
  font-family: "Sarabun", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: white;
  z-index: 9999;
}
</style>
